
<template>
  <div class="container">
    <div class="content">
      <div class="info">
        <img src="@/assets/new_logo_bg.png" alt="" class="leftImg" />
        <img src="@/assets/blue.png" alt="" class="blue" />
        <img src="@/assets/yellow.png" alt="" class="yellow" />
        <img :src="source[prodId].imgUrl" alt="" class="pad" />
        <!-- pad替换？还有个eps知识服务平台 改成此注册页 该平台需修改登录页 -->
      </div>
      <div class="data-view">
        <div class="b-routerWrap">
          <div class="copyright">
            京ICP备17041952号-2 京公网安备11010802029194
          </div>
          <div class="s-routerWrap">
            <div class="login">
              <div class="page-title">
                <h1>
                  <a :href="source[prodId].loginUrl">
                    <i
                      data-v-d7a18bde=""
                      aria-label="图标: left"
                      class="anticon anticon-left"
                      style="cursor: pointer"
                      ><svg
                        viewBox="64 64 896 896"
                        data-icon="left"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                        focusable="false"
                        class=""
                      >
                        <path
                          d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                        ></path></svg
                    ></i>
                  </a>

                  立即注册
                </h1>
                <div style="color: #666; font-size: 12px">
                  若您希望进一步了解（{{source[prodId].title}}），请填写完整的申请信息，提交申请后，即可使用账号密码的方式登录使用平台，试用期限为7天。
                </div>
              </div>
              <div class="form">
                <div>
                  <form @submit.prevent="goApply" v-show="!success">
                    <div class="form-item">
                      <div class="label">真实姓名</div>
                      <div class="input-wrap">
                        <input
                          type="text"
                          v-model="name"
                          class="text"
                          placeholder="请输入真实姓名"
                        />
                      </div>
                    </div>
                    <div class="form-item">
                      <div class="label">学校/机构</div>
                      <div class="input-wrap" @click.stop="">
                        <input
                          type="text"
                          v-model="organ"
                          class="text"
                          @input="getQueryorgan($event)"
                          @focus="getQueryorgan($event)"
                          placeholder="请输入学校/机构"
                        />
                        <div class="organList" v-show="orFlag">
                          <p
                            v-for="item in orlist"
                            :key="item.GROUPID"
                            @click="orSelect(item)"
                          >
                            {{ item.GROUPNAME }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="form-item">
                      <div class="label">职业身份</div>
                      <div class="input" @click.stop="showList = !showList">
                        <div class="text">{{ selectedJob.name }}</div>
                        <div class="triangle"></div>
                        <div class="list" v-show="showList">
                          <dl v-for="(item, key) in professions" :key="key">
                            <dt>{{ item.name }}</dt>
                            <dd
                              v-for="(i, k) in item.children"
                              :key="k"
                              @click="selectJob(item, i)"
                            >
                              {{ i.name }}
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div class="form-item">
                      <div class="label">手机</div>
                      <div class="input-wrap">
                        <input
                          type="text"
                          v-model="mobile"
                          class="text"
                          placeholder="请输入手机号码"
                        />
                      </div>
                    </div>
                    <div class="form-item">
                      <div class="label">邮箱</div>
                      <div class="input-wrap">
                        <input
                          type="text"
                          v-model="email"
                          class="text"
                          placeholder="请输入您的邮箱"
                        />
                      </div>
                    </div>
                    <div class="agreementWrap">
                      <div class="agreement">
                        <Checkbox v-model="agree">我已阅读</Checkbox
                        ><router-link to="/Agreements" target="_blank"
                          >《EPS DATA产品用户使用协议》</router-link
                        >
                      </div>
                    </div>
                    <div class="form-item" style="margin-top: 26px">
                      <button
                        type="primary"
                        class="login-btn"
                        :disabled="buttonDisabled"
                        style="
                          width: 225px;
                          height: 44px;
                          color: white;
                          font-size: 16px;
                          background: #2c3032;
                          border-radius: 4px;
                          cursor: pointer;
                          border: none;
                        "
                      >
                        立即注册
                      </button>
                    </div>
                  </form>
                  <!-- 注册成功后置换 -->
                  <div class="successTab" v-show="success">
                    <Icon type="ios-checkmark-circle-outline" />
                    <div class="green">恭喜您，提交成功！</div>
                    <p>
                      您的手机号即为您的登录账号。
                      <span v-if="proFlag"
                        ><br />您账号的试用有效期截止到{{ endTime }}。</span
                      >
                      <span v-else
                        ><font
                          v-if="prodId == 30 || prodId == 40 || prodId == 50"
                          >密码默认为123456。<br /></font
                        >您的试用期限为7天。</span
                      >
                    </p>
                    <a :href="source[prodId].loginUrl" class="btn_su">去登录</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- 未知部分 -->
            <div
              class="contenter"
              style="height: 100%; padding-top: 88px; padding-bottom: 54px"
            >
              <div class="modalWrap" v-show="modalFlag">
                <div class="modalBox">
                  <div class="title"><i class="gwicon modal"></i>提示</div>
                  <div class="pead">{{ modalMessage }}</div>
                  <div class="btnb">
                    <div class="mbtn succenbtn cright" @click="successModel(1)">
                      确定
                    </div>
                    <div
                      class="mbtn hidebtn cright marright"
                      @click="closeModal()"
                    >
                      取消
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, Icon } from "iview";
import {
  checkEmail,
  checkMobile,
  ajaxPost,
  urlKnowBak,
  urlCjjjdBak,
  urlWgBak,
  urlGmlqBak,
  urlEpsBak,
  urlSoz,
} from "../until/common.js";

export default {
  name: "ApplyOntrial",
  components: {
    Checkbox,
    Icon,
  },
  data() {
    return {
      urlData: "",
      proFlag: false,
      endTime: null,
      modalFlag: false,
      modalMessage: "",
      agree: false,
      name: "",
      organ: "",
      email: "",
      mobile: "",
      success: false,
      buttonDisabled: false,
      showList: false,
      selectedJob: {},
      professions: [
        {
          name: "教师",
          children: [
            { value: "07", name: "助教" },
            { value: "08", name: "讲师" },
            { value: "09", name: "副教授" },
            { value: "19", name: "教授" },
            { value: "10", name: "其他" },
          ],
        },
        {
          name: "学生",
          children: [
            { value: "11", name: "专科" },
            { value: "12", name: "本科" },
            { value: "13", name: "硕士" },
            { value: "14", name: "博士" },
            { value: "15", name: "其他" },
          ],
        },
        {
          name: "在职人员",
          children: [
            { value: "16", name: "初级职位" },
            { value: "17", name: "中级职位" },
            { value: "18", name: "高级职位" },
          ],
        },
      ],
      source: {
        10: {
          title: "EPS数据平台",
          loginUrl: `${urlEpsBak}transform.html`, //`${urlEpsBak}login.html`,//(http://olap.epsnet.com.cn/)
          imgUrl:require('@/assets/eps_reg.png')
        },
        20: {
          title: "中国微观经济数据查询系统",
          loginUrl: `${urlWgBak}login.html`, //(http://microdata.sozdata.com/)
          imgUrl:require('@/assets/wg_reg.png')
        },
        30: {
          title: "长江经济带大数据平台",
          loginUrl: `${urlCjjjdBak}login.html`, //(http://yreb.sozdata.com/)
          imgUrl:require('@/assets/cj_reg.png')
        },
        40: {
          title: "EPS知识服务平台",
          loginUrl: `${urlKnowBak}login.html`, //(http://kdd.epsnet.com.cn/)
          imgUrl:require('@/assets/zsfw_reg.png')
        },
        50: {
          title: "中国革命老区大数据平台",
          loginUrl: `${urlGmlqBak}index.html#/login`, //(http://crod.epsnet.com.cn/)
          imgUrl:require('@/assets/wg_reg.png')
        },
      },
      orFlag: false,
      orlist: [],
    };
  },
  computed: {
    prodId: function () {
      return parseInt(this.$route.query.source);
    },
  },
  methods: {
    successModel(type) {
      var params = {
        prodId: this.prodId,
        groupName: this.organ,
        fullName: this.name,
        postId: this.selectedJob.value,
        postName: this.selectedJob.name,
        mobile: this.mobile,
        email: this.email,
        isContinue: type,
      };
      ajaxPost("ontrial", params).then((res) => {
        if (res.data.success) {
          this.success = true;
          this.modalFlag = false;
          this.$Message.success(res.data.message);
        } else {
          this.$Message.warning(res.data.message);
        }
      });
    },
    closeModal() {
      this.modalFlag = false;
    },
    orSelect(item) {
      this.organ = item.GROUPNAME;
      this.orFlag = false;
    },
    getQueryorgan() {
      if (this.organ) {
        ajaxPost("selectGroupByKeyword", {
          keyword: this.organ,
        }).then((res) => {
          this.orlist = res.data.list;
          this.orFlag = true;
        });
      } else {
        this.orlist = [];
      }
    },
    selectJob(pNode, node) {
      this.selectedJob = {
        name: pNode.name + "-" + node.name,
        value: node.value,
      };
    },
    goApply() {
      this.$Message.config({
        top: 200,
        duration: 2,
      });
      if (!this.agree) {
        this.$Message.warning("请仔细阅读用户使用协议");
        return false;
      }
      if (this.name === "") {
        this.$Message.warning("请填写姓名");
        return false;
      }
      if (this.organ === "") {
        this.$Message.warning("请填写学校/机构");
        return false;
      }
      if (!this.selectedJob.name) {
        this.$Message.warning("请选择职业身份");
        return false;
      }
      if (this.mobile === "") {
        this.$Message.warning("请填写手机");
        return false;
      }
      if (!checkMobile(this.mobile)) {
        this.$Message.warning("手机格式不正确，请重新填写");
        return false;
      }
      if (this.email === "") {
        this.$Message.warning("请填写邮箱");
        return false;
      }
      if (!checkEmail(this.email)) {
        this.$Message.warning("邮箱格式不正确，请重新填写");
        return false;
      }
      this.buttonDisabled = true;
      setTimeout(() => {
        this.buttonDisabled = false;
      }, 1000);
      var params = {
        prodId: this.prodId,
        groupName: this.organ,
        fullName: this.name,
        postId: this.selectedJob.value,
        postName: this.selectedJob.name,
        mobile: this.mobile,
        email: this.email,
      };

      ajaxPost("ontrial", params).then((res) => {
        if (res.data.success) {
          this.success = true;
          this.$Message.success(res.data.message);
        } else {
          if (res.data.isContinue == 0) {
            this.modalFlag = true;
            this.modalMessage = res.data.message;
          } else {
            this.$Message.warning(res.data.message);
          }
        }
      });
    },
    getEndDate() {
      var d = new Date();
      d.setDate(d.getDate() + 7);
      var year = d.getFullYear();
      var month = (d.getMonth() + 1).toString();
      var day = d.getDate().toString();
      if (month.length == 1) {
        month = "0" + month;
      }
      if (day.length == 1) {
        day = "0" + day;
      }
      var dateTime = year + "-" + month + "-" + day;
      this.endTime = dateTime; //结束时间
    },
  },
  mounted() {
    document.onclick = () => {
      this.showList = false;
      this.orFlag = false;
    };
    if (this.prodId == 10) {
      this.proFlag = true;
      this.getEndDate();
    }
    if (document && document.location.hostname === urlSoz) {
      this.urlData = "sozdata";
    } else {
      this.urlData = "epsdata";
    }
  },
};
</script>

<style scoped lang="scss">
// @import '../scss/Apply.scss';
// @import '../scss/common.scss';
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
svg:not(:root) {
  overflow: hidden;
}
.anticon svg {
  display: inline-block;
  font-size: 25px;
}
.container {
  height: 100vh;
  width: 100%;
  background: #fff;
  .content {
    width: 100%;
    height: 100vh;
    position: relative;
    min-height: 768px;
    min-width: 1366px;
    .info {
      position: absolute;
      left: 0;
      width: 710px;
      height: 100%;
      .leftImg {
        width: 100%;
        height: 100%;
      }
      .blue {
        position: absolute;
        left: 18%;
        top: 6%;
      }
      .yellow {
        position: absolute;
        right: 10%;
        top: 76%;
      }
      .pad {
        position: absolute;
        left: 8%;
        top: 24%;
        width: 810px;
      }
    }
    .data-view {
      background: #fff;
      margin-left: 710px;
      height: 100%;
      overflow: hidden;
      .b-routerWrap {
        margin-left: 120px;
        border: 1px solid #fff;
        position: relative;
        height: 100vh;
        .agreement {
          position: absolute;
          bottom: 24px;
          color: #7f7f7f;
          margin-left: calc(50% - 158px);
          /deep/.ivu-checkbox-wrapper {
            color: #7f7f7f;
          }
          /deep/.ivu-checkbox-wrapper,
          /deep/a {
            font-size: 16px;
          }
          span {
            color: #387df8;
          }
        }
        .copyright {
          position: absolute;
          bottom: 40px;
          font-size: 14px;
          margin-left: calc(50% - 166px);
          color: #d4d4d4;
        }
        .s-routerWrap {
          width: 440px;
          height: 520px;
          background: #fff;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          .login {
            width: 440px;
            height: 678px;
            background: #fff;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            max-height: 100vh;
          }
        }
      }
    }
  }
}
.form {
  margin: 20px auto !important;
  .form-item {
    margin-bottom: 18px !important;
    .label {
      font-size: 16px;
      font-weight: 700;
      color: #333;
    }
    .input-wrap {
      /deep/ input {
        margin-top: 5px;
        margin-bottom: 0;
        background: #f3f3f4;
        width: 440px;
        height: 44px;
        outline: none;
        border: 1px solid #d9d9d9;
        padding: 0 10px;
        border-radius: 4px;
      }
    }
    .input {
      width: 440px;
      height: 44px;
      border: 1px solid #d9d9d9;
      position: relative;
      background: #f3f3f4;
      border-radius: 4px;
      .text {
        width: 100%;
        height: 44px;
        line-height: 44px;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        outline: none;
        text-indent: 10px;
      }
      .triangle {
        display: block;
        width: 0;
        height: 0;
        border-top: 5px solid #999;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        right: 10px;
        top: 20px;
      }
      .list {
        position: absolute;
        top: 42px;
        left: -1px;
        width: 440px;
        background: #fff;
        border: 1px solid #e8e8e8;
        z-index: 9;
        line-height: 26px;
        height: 200px;
        overflow: auto;
        dt {
          font-weight: bold;
        }
        dt,
        dd {
          text-indent: 20px;
        }
        dd {
          cursor: pointer;
        }
        dd:hover {
          background: #f0f3f6;
        }
      }
      .organList {
        position: absolute;
        left: 0px;
        top: 35px;
        z-index: 3;
        width: 100%;
        height: auto;
        max-height: 132px;
        border: 1px solid #e8e8e8;
        background: #fff;
        overflow-y: auto;
        p {
          cursor: pointer;
          line-height: 24px;
          padding: 0 8px;
        }
      }
    }
  }
}
//注册成功样式
.successTab {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 440px;
  max-height: 30vh;
  .ivu-icon {
    color: #1aba3f; //#1aba3f;
    font-size: 70px;
    font-weight: bold;
    font-family: Ionicons;
  }
  .green {
    color: #1aba3f; //#1aba3f;
    font-size: 24px;
    line-height: 40px;
  }
  p {
    line-height: 30px;
  }
  .btn_su {
    display: inline-block;
    width: 320px;
    height: 34px;
    line-height: 34px;
    margin: 50px auto 0;
    border-radius: 4px;
    background: #2c3032;
    color: #fff;
  }
  .cmimg {
    height: 76px;
    width: 76px;
    margin: 0 auto;
    background: url(/img/iSuccess.png) no-repeat;
  }
}
// 弹窗样式
.modalWrap {
  height: 100%;
  width: 100%;
  background: rgba(1, 1, 1, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;

  .modalBox {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 520px;
    height: 320px;
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;

    .title {
      height: 54px;
      line-height: 54px;
      font-size: 16px;
      font-weight: bold;
    }

    .pead {
      line-height: 24px;
      height: auto;
      padding-top: 8px;
      padding-left: 38px;
      padding-right: 38px;
      padding-bottom: 28px;
      box-sizing: border-box;
      .ptext {
        line-height: 30px;
      }
      .ptextem {
        text-indent: 2em;
        line-height: 30px;
      }
    }

    .btnb {
      height: 40px;
      padding: 0 28px;
      box-sizing: border-box;
      .mbtn {
        cursor: pointer;
        padding: 6px 14px;
        box-sizing: border-box;
        border-radius: 4px;
      }

      .hidebtn {
        border: 1px solid #d3d6d8;
        color: #e8e8e8;
      }

      .succenbtn {
        color: #fff;
        background: #e8e8e8;
      }

      .marright {
        margin-right: 10px;
      }
    }
  }
}
</style>